body {
  margin: 0;
}


.login-page {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, #800080a6, #0000ffa8);
}

.login-container {
  width: 100%;
  max-width: 400px;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #04030324;
}

.login-form {
  max-width: 300px;
  margin: 0 auto;
}

.login-title {
  text-align: center;
  margin-bottom: 20px;
  color: white;
}

.login-form-button {
  width: 100%;
  margin-bottom: 12px;
  background: linear-gradient(to right, #800080a6, #0000ffa8);
}

.login-form-button:hover {
  width: 100%;
  margin-bottom: 12px;
  background: #404bff !important;
}

.login-form .ant-checkbox-wrapper {
  color: white;
}

.menu-with-margin {
  margin-top: 16px; /* Adjust the margin as needed */
}

.header-content {
  display: flex;
  align-items: center;
  height: 100%; /* Full height of the header */
}

.user-name {
  font-size: 16px;
  color: #fff; /* White text color */
}

.ant-picker-clear {
  display: none;
}

.print-table {
  border-collapse: collapse;
  width: 100%;
}

.print-table th, .print-table td {
  border-top: 1px solid #ddd; /* Top border only */
  border-bottom: 1px solid #ddd; /* Bottom border only */
  border-left: none; /* Remove left border */
  border-right: none; /* Remove right border */
}





.header-container {
  border: 1px solid #000;
  padding: 10px;
  margin: 0;
  box-sizing: border-box;
  width: 100%;
}

.header-row {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 5px 0;
  border-bottom: 1px solid #000;
}

.header-title {
  text-align: center;
  font-weight: bold;
  margin: 0;
}

.header-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.print-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 10px; /* Adjust font size to fit content */
  margin-bottom: 10px;
}

.print-table th, .print-table td {
  border: 1px solid #000;
  padding: 5px;
}

.print-table th {
  background-color: #f0f0f0;
  font-weight: bold;
}

.print-table .total-cell {
  font-weight: bold;
}







/* Base styles */
.site-layout {
  margin: 0; /* Remove default margin */
}

.header-container {
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content-container {
  margin: 8px; /* Reduce margin for smaller screens */
  padding: 0; /* Remove default padding */
}

.content-background {
  padding: 16px; /* Reduce padding to minimize extra space */
  min-height: 360px;
  margin: 0 auto; /* Center the content horizontally */
  max-width: 100%; /* Ensure it does not exceed the viewport width */
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .ant-form-item {
    margin-bottom: 0px; /* Adjust this value as needed */
  }
  .site-layout {
      margin: 0; /* Remove margins for small screens */
  }

  .header-container {
      padding: 0 8px;
  }

  .content-container {
      margin: 4px; /* Further reduce margin for very small screens */
  }

  .footer-container {
      padding: 0 8px;
  }

  .menu-with-margin {
      padding-top: 56px; /* Adjust top padding to account for header */
  }

  .logo {
      display: none; /* Hide logo on small screens */
  }

  .site-layout-background {
    padding: 0px !important;
      margin-left: 0; /* Adjust content margin for smaller screens */
  }

  .menu-button {
      display: block; /* Ensure the menu button is visible */
  }

  .ant-menu-item {
      padding: 3px 16px; /* Adjust padding for menu items */
  }

  .ant-menu-item .anticon {
      margin-right: 8px; /* Space between icon and text */
  }
}

/* Ensure content is not too wide on larger screens */

